import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/remote-work.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Remote Teams | Camelo"
        metaDescription="Put everything under your control & eliminate geographical limitations for your remote team with the Camelo scheduling & attendance tracking app."
        header="Take the guesswork out of scheduling and managing your remote teams."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
